/*!
 =========================================================
 * Material Dashboard React - Custom CSS
 =========================================================
 * Date: 27/10/2020
 * Last Modified: 27/10/2020 11.35
 =========================================================
 * Copyright 2020 Aristotelistes Dev Team
 =========================================================
 */

/* Epilogi Vivliou - Start */

/* triantafyllakos */
@import "../fonts/fonts.css";
/* triantafyllakos */

.MuiTreeItem-label{
	margin:5px 0!important;
}


.pateras{
	padding:10px !important;
	min-width:400px;
}

.Mui-selected > .MuiTreeItem-content .pateras{
}

.MuiTreeItem-label.pateras:hover{
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.paidi{
	padding:10px !important;
	border-radius:3px;
	min-width:400px;
}

.Mui-selected > .MuiTreeItem-content .paidi{
}

.MuiTreeItem-label.paidi:hover{
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.makeStyles-card-57 {
  }

  /* Epilogi Vivliou - End */


 /* ReactTable (px  adminUsersGroupsPrivs) */

.ReactTable .rt-thead th{
	font-weight:bold !important;
	color:rgba(0, 0, 0, 0.60) !important;
	font-size:0.95rem !important;
}


.ReactTable .rt-thead .rt-tr{
	border:1px solid gray !important;
	margin-bottom:20px;
	border-width:2px 0 !important;
}

.makeStyles-formControl-166{
	padding-top:10px !important;
}

 /* ReactTable - End */


  /*links ennoias ston editor*/
 .WikiLink{
	text-decoration:underline;
	color:blue;
 }

 .tab {
	 margin-left: 0.75cm;
 }

.sectionWorkAndBookHeader {
	font-weight: bold;
	color: #c00000;
	font-size:1.25em!important;
	/* background-color: #d0d0d0 !important; */
}
.sectionWorkAndBookHeader .archaic_text { font-size:1em!important; }

label.MuiRating-label {
	color: inherit !important;
	font-size: inherit !important;
}

/* triantafyllakos */
:root { --basecolor:#6400f0!important; --basecolorrgb:100,0,240!important; --secondary:#ff5000; --thirdcolor:#00dd99; --thirdcolor2:#eebb99; --fourthcolor:#f09600; --fourthcolor2:#98687b; --initcolor:#6400f0; --gridcolor:#6400f0; }
.MuiAppBar-root { padding:20px 0; }
#root *[class*="makeStyles-content-"], #central_content { margin-top:100px!important; margin-bottom:0!important; min-height:75vh!important; }
#mainsidebar .MuiListItem-root { padding-top:5px!important; padding-bottom:5px!important; }
#mainsidebar > div:first-child { padding-top:48px!important; }
body { background-color:#fff!important; }
* { font-family:"Commissioner"; }
*[class*="makeStyles-mainPanel"] { overflow-x:hidden!important; }
*[class*="MuiDrawer-"] { transition:all .2s ease-in-out; }
h1, h2, h3, h4, h5, h6 { font-family:"Commissioner"!important; text-align:left; font-weight:500!important; }
.MuiTypography-h1,.MuiTypography-h2,.MuiTypography-h3,.MuiTypography-h4,.MuiTypography-h5,.MuiTypography-h6 { font-family:"Commissioner"!important; }
*[class*="MuiTypography-"] { font-family:"Commissioner"; }
.hidden { display:none!important; }
a { color:#000!important; transition:all .2s ease-in-out; }
a span { transition:all .2s ease-in-out; }
a:hover { color:#000!important; text-decoration:underline; }
header { background-color:rgba(var(--basecolorrgb),100)!important; }
footer { position:relative!important; padding:0 0 25px!important; line-height:1!important; border-top:0!important; text-align:center!important; }
footer * { color:var(--basecolor)!important; }
footer > div { padding:0!important; }
footer p { position:relative; width:100%!important; clear:both!important; float:none!important; text-align:center!important; }
footer > div > div { display:none; }
h5 { margin:0 0 25px!important; }
.MuiBreadcrumbs-root { margin:0 0 25px!important; }
.MuiBreadcrumbs-li * { color:#333!important; font-size:14px!important; line-height:1.5!important; font-family:"Commissioner"!important; margin:0!important; padding:0!important; }
.MuiPaper-root { max-width:100%!important;  }
.MuiPaper-elevation1 { box-shadow:none!important; }
.MuiListItemIcon-root { display:none!important; }
.MuiLink-underlineHover { text-decoration:none!important; }
#loginMenuItem { min-width:160px!important; }
#intro_text { min-height:150px; position:relative; left:50px; margin:50px auto 75px; width:50vw!important; max-width:720px; font-size:24px; color:var(--basecolor)!important; }
#intro_logo { width:100px!important; position:absolute; left:-130px; top:-20px; }
#intro_text:before { display:block; content:" "; position:absolute; left:-115px; top:-15px; width:100px; height:100px; border-radius:50%; opacity:.5; background:var(--thirdcolor)!important; }
#mainmenu { line-height:1.25; width:100vw!important; background:transparent!important; box-shadow:none!important; margin:0 auto!important; padding:25px!important; }
#mainmenu .mainmenulink span { border-bottom:solid 1px transparent; }
#mainmenu .mainmenulink { font-size:56px; line-height:1.25; font-weight:700; color:#333!important; }
#mainmenu .mainmenulink:hover { text-decoration:none; border-bottom:0!important; color:var(--basecolor)!important; }
#mainmenu .mainmenulink span { border-bottom:solid 3px var(--basecolor)!important; padding:2px 0; }
#mainmenu .mainmenulink:not(:last-child):after { content:url("../../images/arrow.svg"); display:inline-block!important; position:relative; top:5px; width:75px!important; height:75px!important; background-size:75px 75px; padding:0 15px; border-bottom:0!important; }
#mainmenu .mainmenulink:nth-child(5) { color:#333!important; }
#mainmenu .mainmenulink:nth-child(5) span { border-bottom:solid 3px var(--thirdcolor)!important; }
#mainmenu .mainmenulink:nth-child(5) span:hover { color:var(--thirdcolor); }
#mainmenu .mainmenulink:nth-child(6), #mainmenu .mainmenulink:nth-child(7) { color:#333!important; }
#mainmenu .mainmenulink:nth-child(6) span, #mainmenu .mainmenulink:nth-child(7) span { border-bottom:solid 3px var(--fourthcolor)!important; }
#mainmenu .mainmenulink:nth-child(6) span:hover, #mainmenu .mainmenulink:nth-child(7) span:hover { color:var(--fourthcolor); }
#mainmenu .mainmenulink:nth-child(8),#mainmenu .mainmenulink:nth-child(9) { color:#333!important; }
#mainmenu .mainmenulink:nth-child(8) span,#mainmenu .mainmenulink:nth-child(9) span { border-bottom:solid 3px var(--secondary)!important; }
#mainmenu .mainmenulink:nth-child(8) span:hover,#mainmenu .mainmenulink:nth-child(9) span:hover { color:var(--secondary); }
#mainmenu .mainmenulink:nth-child(10) { color:#333!important; }
#mainmenu .mainmenulink:nth-child(10) span { border-bottom:solid 3px #333!important; }
#mainmenu .mainmenulink:nth-child(10) span:hover { color:#000!important; border-bottom:solid 3px #000!important; }
#mainmenu .mainmenulink span.mainmenulinkdescription { display:none!important; }
#intro_text > span.color1 { color:var(--basecolor)!important; }
/*#intro_text > span.color2 { color:var(--thirdcolor)!important; }
#intro_text > span.color3 { color:var(--fourthcolor)!important; }
#intro_text > span.color4 { color:var(--secondary)!important; }
#intro_text > span.color5 { color:#000!important; }*/
#searchKeyword { width:100%!Important; }
#searchflex { align-items:flex-start!important; }
#lexicon_list li { display:inline!important; padding:0!important; margin:0 10px 10px 0!important; }


.MuiGrid-container { align-items:flex-start!important; }
*[class*="MuiTypography-body"] { font-family:"Commissioner"!important; }
*[class*="MuiCardActionArea-"] { background:#f3f3f3!important; }
*[class*="makeStyles-cardContentTrans-"],*[class*="makeStyles-LblClass-"] { color:var(--basecolor)!important; }
*[class*="makeStyles-introImage-"] { width:100%!important; height:200px; }
h4[class*="MuiTypography-h4"] { padding-bottom:15px!important; }
.pateras { border-radius:0!important; width:100%!important; color:var(--basecolor)!important; font-family:"GFS Galatea"!important; font-size:1.25em!important; }
.paidi { background:transparent!important; color:var(--basecolor)!important; border:solid 1px var(--basecolor)!important; }
.paidi:hover { background:transparent!important; color:var(--secondary)!important; border-color:var(--secondary)!important; }
.archaic_text { font-family:"GFS Galatea"!important; font-style:normal!important; font-size:18px!important; }
.archaic_text b { font-family:"GFS Galatea"!important; }
.basecolorbg { background:var(--basecolor)!important; }
.whitebg { background:#fff!important; }
*[aria-label="breadcrumb"],.transparentbg { background:transparent!important; }
*[aria-label="breadcrumb hidden"] { display:none!important; }
.transparentbg { text-align:center!important; }
#searchinstructions { padding:25px; background:#fff; margin-bottom:25px; }
#mainsearchform, #mainsearchresults { border-radius:4px; box-shadow:0 0 0 #fff; padding:25px!important; background:#fff!important; opacity:.9; }
#belowsearch { margin-top:25px!important; }
input#searchLines, input#searchKeyword { width:100%!important; font-size:18px!important; margin:0 auto!important;  }
input#term-search { width:100%!important; font-size:18px!important; }
.MuiPagination-root { text-align:center!important; margin:25px auto 0!important; }
.searchresultoccurence { width:100%!important; padding:10px 0!important; display:flex; justify-content:space-between; align-items:flex-start; border-bottom:dotted 1px #ccc; }
.smalltitle { font-size:14px!important; font-weight:normal; }
#mainTextsViewer label { font-size:12px!important; }
#belowMainTextsViewer { background:#f3f3f3!important; }
#belowMainTextsViewer .MuiGrid-item { padding:0 10px!important; }
.MuiButtonBase-root { border-radius:0!important; }
*[role="tablist"] { justify-content:center!important; }
button[aria-label*="page"] { border-radius:16px!important; }
#lyricsgrid { width:100%!important; margin:0!important; padding:0!important; }
#lyricsgrid .MuiGrid-grid-md-4 { flex-basis:50%!important; }
ul[role="listbox"] > li[aria-selected="true"],ul[role="listbox"] > li:hover { background:var(--basecolor)!important; }
a[href*="/Lexicon/"] { font-family:"GFS Galatea"!important; font-style:normal!important; font-size:16px!Important; margin:0 2px 10px!important; padding:10px 10px 7px!important; background:var(--basecolor)!important; color:#fff!important; font-weight:500; border-radius:4px!important; display:inline-block!important; line-height:1!important; }
a[href*="/Lexicon/"]:hover { text-decoration:none; opacity:.8; }
label[for="contained-button-file-excel"] .makeStyles-primary-31, label[for="contained-button-file-excel"] .makeStyles-primary-126 { border-radius:0!important; background-color:rgba(var(--basecolorrgb),.70)!important; }
ul[role="tree"]#fixedtree { position:fixed!important; }
h4 { color:var(--basecolor)!important; }
#lexicon_alpha { text-align:center!important; background:#f5f5f5!important; padding:20px!important; font-size:18px; border-radius:8px; margin:20px auto!important; box-shadow:0 0 0 #fff; overflow-wrap:break-word!important; }
#lexicon_alpha a:hover span, #lexicon_alpha a span[class*="-selected_"] { color:var(--basecolor)!important; }
#searchLines { margin:0 auto!important; }
.forum-list .horizontally.grid, .home-list .horizontally.grid { background:#f5f5f5!important; border:none!important; }
.forum-list .grammi-titlos .ui, .home-list .grammi-titlos .ui { background:#fff!important; border-bottom:solid 1px #ccc!important; }
.homeAddButton button { margin:25px auto 0!important; }
.newThread-hidden button { margin:0 0 10px 20px!important; }
.newThread-show { padding:25px; background:#f5f5f5; }
button[class*="makeStyles-rose"] { box-shadow:none!important; }
.newThread-wrapper { background:#fff!important; padding:10px; }
*[class*="MuiCardMedia-root makeStyles-labImage"],*[class*="MuiCardMedia-root makeStyles-seminarImage"] { width:100%!important; height:200px!important; }
.AristosLabImage { width:100%!important; height:200px!important; }
*[class*="makeStyles-labImage"]:not(.MuiCardMedia-root) { border:none!important; height:auto!important; }
*[class*="makeStyles-seminarImageCell"] { border:0!important; padding:0!important; }
*[class*="makeStyles-cardIcon"] { background:#ddd!important; }
*[class*="makeStyles-primaryCardHeader"] h4 { background:transparent!important; color:var(--basecolor)!important; }
*[class*="makeStyles-cardHeader"] h4, *[class*="makeStyles-cardHeader"] *[class*="-h6"] { color:var(--basecolor)!important; }
#lyricsgrid *[class*="makeStyles-cardHeader"] { background:var(--basecolor)!important; padding:10px 20px 0!important; margin-bottom:15px!important; }
#lyricsgrid *[class*="makeStyles-cardHeader"] * { color:#fff!important; }
a[href*="TRSeminarView"] { background:#f3f3f3!important; } 
*[class*="makeStyles-pills"] { background:#e5e5e5!important; }
button[class*="makeStyles-pills"] { border-radius:4px!important; }
button[class*="makeStyles-pills"][aria-selected="true"] { box-shadow:0 0 0 #fff; }
*[class*="MuiTreeItem-root Mui-selected"][aria-selected="true"] .paidi { border:solid 1px var(--secondary)!important; color:var(--secondary)!important; }
div[class*="makeStyles-workSelectionField"] { background:#f5f5f5!important; padding:10px!important; margin-bottom:25px!important; }
.MuiButton-text, .MuiButton-contained,.MuiButton-containedPrimary { background:var(--basecolor)!important; }
.MuiButtonBase-root.MuiCardActionArea-root:hover { text-decoration:none!important; }
h6 { text-transform:none!important; color:var(--basecolor)!important; font-family:"Commissioner"!important; margin-bottom:10px!important; }
.rt-table { background:#fff!important; padding:30px 20px!important; border:solid 1px #ccc!important; }
.MuiPaginationItem-textPrimary[aria-current="true"] { background:var(--basecolor)!important; }
.MuiBottomNavigation-root { background:transparent!important; }
.MuiSvgIcon-root path { fill:var(--basecolor); }
header .MuiSvgIcon-root path { fill:#fff!important; }
.MuiButton-text { background:var(--basecolor)!important; border-radius:0!important; padding:10px 15px!important; color:#fff!important; }
.MuiButton-label * { fill:#fff!important; }
.MuiButton-text:hover { background:#999!important; }
.MuiDrawer-paper { visibility:visible!important; transition:all .2s ease-in-out;  }
.MuiDrawer-paper .MuiDivider-root { margin:5px auto!important; width:94%!Important; border:0!important; outline:0!important; background:transparent!important; border-top:dotted 2px var(--basecolor)!important; }
.actions-right button svg * { fill:#fff!important; }
.MuiTab-wrapper { color:var(--basecolor)!important; }
.MuiListItem-button:hover:not([role='menuitem']), .MuiListSubheader-root:hover:not([role='menuitem']) { color:#fff!important; }
.MuiListItem-root:hover span { color:var(--basecolor)!important; }
li[role="option"][aria-disabled="true"] { color:#fff!important; }
.sectionWorkAndBookHeader { background:var(--basecolor)!important; color:#fff!important; }
.pagination-bottom .-pagination { align-items:center!important; }
.pagination-bottom .-pagination .-center { flex-basis:60%!important; }
.pagination-bottom .-pagination .-center *[class*="makeStyles-grid"] { display:flex; justify-content:space-between; align-items:flex-start; }
.pagination-bottom .-pagination .-center *[class*="makeStyles-grid"], .pagination-bottom .-pagination .-center *[class*="makeStyles-grid"] { flex-basis:50%!important; }
.pagination-bottom .-pagination .-center .MuiFormControl-root { width:100%!important; }
#entrydisplay > .archaic_text { margin:0 0 25px!important; font-size:20px!important; }
#entrydisplay > .archaic_text span { background:transparent!important; position:relative; top:-2px; }
#entrydisplay > .archaic_text span * { margin:0; padding:0!important; height:auto!important; box-shadow:none!important; }
#entrydisplay div[class*="makeStyles-cardBody-"] p { padding:5px 0; }
button[class*="formCheckButton"],button[class*="-disabled"] { background:#ccc!important; }
button[class*="formCheckButton"]:not([class*="-disabled"]) { background:var(--secondary)!important; }
button[class*="-danger"] { background:var(--thirdcolor)!important; }
*[class*="roseCardHeader"] { box-shadow:none!important; }
*[class*="roseCardHeader"] h4 { background:transparent!important; }
.ui.error.message { font-size:18px; padding:20px 0; }
#userprofile, #userprofile *[class*="MuiGrid-container"], #userprofile *[class*="MuiPaper-root makeStyles-paper"] { background:transparent!important; box-shadow:none!important; }
.newPost-show input#keyword-search { background:transparent!important; }
label.MuiInputLabel-formControl { color:var(--basecolor)!important; }
*[class*="makeStyles-primaryCardHeader"]:not([class*="makeStyles-cardHeaderText"]):not([class*="makeStyles-cardHeaderIcon"]) { background:var(--basecolor)!important; }
*[class*="makeStyles-primaryCardHeader"]:not([class*="makeStyles-cardHeaderText"]):not([class*="makeStyles-cardHeaderIcon"]) h4 { color:#fff!important; }
*[class*="makeStyles-highlighted"] { background:var(--thirdcolor)!important; color:#fff!important; font-family:"GFS Galatea"!important; }
*[class*="makeStyles-primaryCardHeader"] svg path { fill:#fff!important; }
*[class*="makeStyles-card"] { height:auto!important; }
.AristosCardHeader { background:var(--basecolor)!important; color:#fff!important; }
.AristosCardHeader svg path { fill:#fff!important; }
.AristosCardHeader * { color:#fff!important; }
#belowMainTextsViewer .react-swipeable-view-container > div { background:#eee!important; padding:25px!important; }
#belowMainTextsViewer .react-swipeable-view-container > div a:not([href*="/Lexicon/"]) { background:transparent!important; }
*[class*="makeStyles-bordered"] { border:dotted 1px #aaa!important; }
*[class*="makeStyles-seminarImage"]:not(.MuiCardMedia-root),*[class*="makeStyles-labImage"]:not(.MuiCardMedia-root),*[class*="makeStyles-personImage"]:not(.MuiCardMedia-root) { width:100%!important; height:auto!important; }
#labpreview h5, #labfullview h5 { margin-top:25px!important; padding-top:10px; color:var(--basecolor); border-top:dotted 1px var(--basecolor); }
#labpreview *[class*="makeStyles-bordered"] { border:none!important; }
#labpreview * { border:none!important; font-family:"Commissioner"!important; }
#labhierarchytree { padding-right:20px!important; }
#labsearch { width:50%!important; margin:0 auto 50px!important; }
#labsearch > .MuiTextField-root { width:75%!important; }
#labviewpagecontainer { padding-left:0px; padding-right:0px; }
#labviewpagecontainer h4 { margin-bottom:25px!important; }
#labviewpagecontainer img, #labviewpagecontainer iframe { width:100%!important; margin:25px auto!important; }
a[href*="/CollTranslation"] { font-family:"GFS Galatea"!important; font-size:1.1em!important; }

@media screen and (min-width: 1920px) {
	#mainmenu { padding:25px 10vw 0 !important; }
}

@media screen and (min-width: 1366px) and (max-width:1919px) {
}

@media screen and (max-width:1365px) {
}

@media screen and (max-width: 960px) {	
	/* triantafyllakos */
	#intro_text { font-size:16px!important; margin:0 auto 50px!important; width:65vw!important; }
	#intro_logo { width:75px!important; left:-100px!important; }
	#intro_text:before { width:75px!important; height:75px!important; left:-85px!important; }
	#mainmenu { padding:25px 0!important; }
	#mainmenu .mainmenulink { font-size:26px!important; }
	#searchflex > .MuiGrid-root { flex-basis:100%!important; flex-grow:1!important; max-width:100%!important; }
	#searchflex > .MuiGrid-root:nth-child(1) { margin-bottom:50px!important; }
	.homeContainer { padding:0!important; }
	.home-list .ui.grid, .forum-list .ui.grid { flex-direction:row!important; flex-wrap:wrap!important; }
	.home-list .ui.grid .column, .forum-list .ui.grid .column { flex-basis:100%!important; width:100%!important; float:none!important; margin-bottom:25px!important; }
	/* triantafyllakos */
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 600px) {
}
