.searchContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding:1rem;
}

.search-list {
  width: 100% !important;
}

.searchContainer a:-webkit-any-link {
  cursor: pointer;
  color: black;
  font-weight: bold;
}

.search-row {
  display: flex;
  flex-direction: row;
}

.search-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-name {
  font-weight: bold;
}

.search-error {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 33%;
  color: rgba(231, 76, 60,1.0);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.search-list .ui.grid {
	margin: 10px;
	padding: 10px;
	margin-bottom: 20px;
	border-radius: 4px;
	border: 1px solid #ddd;
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
	padding: 10px;
	display:flex;
}


.search-list .six.wide.column{
	width:50%;
	float:left;
	display:block;
}

.search-list .two.wide.column{
	width:16.666%;
	float:left;
	display:block;
}

.search-list .four.wide.column{
	width:33.333%;
	float:left;
	display:block;
}

.search-list .grammi-titlos .ui.grid{
	font-size:12px !important;
	margin:0 !important;
	min-height: unset !important;
	padding:10px;
	box-shadow: none !important;
	border-width: 1px 0px;
	border-radius:0;
	margin-bottom:2rem !important;
}

.search-meta a {
  color: gray !important;
  font-size: 12px;
}

.search-meta b{
  color: gray !important;
  font-size: 10px;
}
